.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px; /* Adjust for navbar height */
    /* padding-bottom: 80px; */
    background: linear-gradient(135deg, #0d0d0d, #1a1a1a);
    color: #fff;
    text-align: center;
    min-height: 100vh;
}

.about-content {
    max-width: 1080px;
    width: 100%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #ffdd57;
    margin-bottom: 20px;
}

.about h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    animation: fadeIn 2s ease-in-out;
    color: #ffdd57;
}

.about-content > p {
    font-size: 1.2em;
    margin-bottom: 20px;
    line-height: 1.6;
    animation: slideIn 2s ease-in-out;
    padding-inline: 12px;
}

.about h2 {
    font-size: 2em;
    margin-top: 40px;
    margin-bottom: 20px;
    animation: fadeIn 2s ease-in-out;
    color: #ffdd57;
}

.skills, .professional-summary, .key-achievements, .problem-solving {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    animation: fadeIn 2s ease-in-out;
}
.problem-solving a{
    color:#ffdd57;
}
.professional-summary{
    .card p{
        margin-bottom: 20px;
    }
}

.card {
    margin-bottom: 20px;
    width: clamp(320px, 90%, 90%);
    overflow: hidden;
    padding-right: 20px;
}

.card p {
    text-align: left;
    font-size: 1.2em;
    margin: 0;
    line-height: 1.6;
    animation: slideIn 2s ease-in-out;
    text-wrap: wrap;
}

.key-achievements ul, .problem-solving ul {
    list-style: none;
    padding: 0;
    animation: slideIn 2s ease-in-out;
}

.key-achievements li, .problem-solving li {
    padding: 10px;
    text-align: left;
}

.social-links {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    animation: fadeIn 2s ease-in-out;
    flex-wrap: wrap;
}

.social-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 10px;
    transition: transform 0.3s, background 0.3s;
}

.social-icon:hover {
    transform: scale(1.1);
    background: rgba(255, 255, 255, 0.2);
}

.social-icon p {
    margin: 0;
    font-size: 0.9em;
}

.experience {
    background: linear-gradient(135deg, #1a1a1a, #2a2a2a);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.experience h2{
    /* padding-top: 20px; */
}

.experience-item {
    padding: 20px;
    border-radius: 18px;
    text-align: left;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: #0d0d0d;
    border: 1px inset #2a2a2a;
}

.experience-item::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 221, 87, 0.2), transparent 70%);
    transition: transform 0.5s;
    transform: scale(0);
    z-index: 0;
}


.experience-item:hover {
    transform: translateY(-10px);
}

.experience-item h3 {
    font-size: 1.6em;
    margin-bottom: 10px;
    color: #ffdd57;
    z-index: 1;
    position: relative;
}

.experience-item p {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #fff;
    z-index: 1;
    position: relative;
}

.experience-item ul {
    list-style-type: disc;
    padding-left: 20px;
    z-index: 1;
    position: relative;
}

.experience-item li {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #fff;
    z-index: 1;
    position: relative;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}   