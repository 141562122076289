.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    /* Adjust for navbar height */
    /* padding-bottom: 80px; */
    /* Adjust for footer height */
    background: linear-gradient(135deg, #0d0d0d, #1a1a1a);
    color: #fff;
    text-align: center;
    min-height: 100vh;

}

.home-content {
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    margin-bottom: 40px;
}

.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #ffdd57;

}

.social-links {
    justify-content: center;
}

.social-icon {
    background-color: #0d0d0d;
    padding: 8px 12px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(66, 66, 66, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    max-width: 80px;
    gap: 18px;

    p {
        margin: 0;
        font-size: 12px !important;
    }
}

.social-icon img {
    width: 100%;
    height: 100%;
}

.social-icon:hover {
    transform: scale(1.2);
}

.home h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.home-content>p {
    font-size: 1.2em;
    margin-bottom: 40px;
    max-width: 720px;
    margin-inline: auto;
}

.overview,
.selling-projects {
    width: 100%;
    padding: 40px 20px;
    margin-bottom: 40px;
}

.overview {
    background: #1a1a1a;
    margin-inline: auto;
    text-align: center;
}

.overview p {
    max-width: 720px;
    text-align: center;
    margin-inline: auto;
}


.selling-projects {
    background: #0d0d0d;
}

.overview h2,
.company-projects h2,
.selling-projects h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #ffdd57;
}
.npm-packages {
    width: 100%;
    padding: 40px 20px;
    background: #0d0d0d;
    margin-bottom: 40px;
    text-align: center;
}

.npm-packages h2 {
    font-size: clamp(1.8rem, 2.5vw, 2.5rem);
    color: #ffdd57;
    margin-bottom: 20px;
}

.package-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.package-item {
    background: #444;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    border-radius: 18px;
    width: 90%;
    max-width: 800px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
}

.package-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
}

.package-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.logo {
    width: 50px;
    height: 50px;
}

.package-content {
    text-align: center;
    width: 100%;
}

.package-content h3 {
    font-size: 1.5rem;
}

.package-content p {
    font-size: 1.1rem;
    color: #ddd;
}

.package-content pre {
    background: #333;
    padding: 10px;
    border-radius: 5px;
    color: #ffdd57;
    font-size: 1rem;
    overflow-x: auto;
    text-align: left;
}

.keywords {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    margin-top: 10px;
}

.keywords span {
    background: #000;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1rem;
    color: #fff;
}

.package-links {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.package-icon a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #444;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    transition: transform 0.3s;
}

.package-icon:hover a {
    transform: scale(1.1);
}

.icon {
    width: 30px;
    height: 30px;
}

.tooltip {
    position: absolute;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.75);
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 0.9rem;
    white-space: nowrap;
}

.explore-more {
    margin-top: 20px;
}

.explore-more a {
    color: #ffdd57;
    text-decoration: underline;
}

/* RESPONSIVE DESIGN */

@media (max-width: 768px) {
    .package-item {
        width: 95%;
        padding: 15px;
    }

    .logo {
        width: 50px;
        height: 50px;
    }

    .package-content h3 {
        font-size: 1.3rem;
    }

    .package-content p {
        font-size: 1rem;
    }

    .keywords span {
        font-size: 0.9rem;
    }

    .package-links {
        flex-wrap: wrap;
        justify-content: center;
    }

    .package-icon a {
        width: 40px;
        height: 40px;
    }

    .icon {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 480px) {
    .package-item {
        width: 100%;
        padding: 10px;
    }

    .logo {
        width: 32px;
        height: 32px;
    }

    .package-content h3 {
        font-size: 1.2rem;
    }

    .package-content p {
        font-size: 0.9rem;
    }

    .keywords span {
        font-size: 0.8rem;
    }

    .package-links {
        flex-direction: row;
        justify-content: center;
    }

    .package-icon a {
        width: 35px;
        height: 35px;
    }

    .icon {
        width: 22px;
        height: 22px;
    }
}
.company-projects {
    padding: 40px 20px;
    /* margin-bottom: 40px; */
}

.project-list {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); */
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    gap: 30px;
    justify-content: center;
    padding: 20px;
}

.project-item {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s, box-shadow 0.3s;
    background: linear-gradient(135deg, #333, #444);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 100%;
    max-width: 600px;
    min-width: 320px;
}

.project-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background);
    background-size: cover;
    background-position: center;
    filter: blur(10px);
    z-index: 0;
    transition: transform 0.5s;
}

.project-item:hover::before {
    transform: scale(1.1);
}

.project-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.7);
}

.project-content {
    position: relative;
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    padding: 20px;
}

.project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.project-item h3 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #ffdd57;
}

.project-item .project-details {
    text-align: left;
}

.project-item .project-details p {
    font-size: 1.2em;
    margin-bottom: 15px;
}

.project-item .project-details span {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    padding-left: 12px;
}

.project-links {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 5px;
}

.project-item .project-links a {
    color: #000;
    text-decoration: none;
    transition: color 0.3s;
    font-size: 1.2em;
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    
}

.project-item .project-links a:hover {
    color: #ffdd57;
}

.what-i-offer {
    width: 100%;
    padding: 40px 60px;
    background: #1a1a1a;
    margin-bottom: 40px;
}

.what-i-offer h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #ffdd57;
}

.offer-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 20px;
}

.offer-item {
    background: #333;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s, box-shadow 0.3s;
}

.offer-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.7);
}

.offer-item h3 {
    font-size: 2em;
    color: #ffdd57;
    margin-bottom: 10px;
}

.offer-item h4 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #fff;
}

.offer-item p {
    font-size: 1.2em;
    color: #ccc;
}

@media  (max-width: 768px) {
    .package-item {
        width: 100%;
    }
    .what-i-offer {
        padding-inline: 20px;
    }
    .package-item{
        /* flex-direction: column; */
        padding: 20px;
    }
    .package-logo{
        display: none;
        
    }

}

@media (max-width: 600px) {
    .company-projects{
        padding: 0;
    }
    .offer-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        gap: 20px;
    }
}
